import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { DLPInspect } from '../dlpinspect';
import { BQTableHeader } from '../bqtable-header';
import { BQTableRow } from '../bqtable-row';

@Component({
  selector: 'app-dataprocessing',
  templateUrl: './dataprocessing.component.html',
  styleUrls: ['./dataprocessing.component.css']
})


export class DataprocessingComponent implements OnInit {

  dataLoaded = false;
  progress = 0;
  dataService: DataService;
  userService: UserService;
  projectId: string;
  datasetId: string;
  tableId: string;
  dlpInspect: DLPInspect;
  dlpFindings: [];

  constructor(dataService: DataService, userService: UserService,
    private route: ActivatedRoute
  ) {
    this.dataService = dataService;
    this.userService = userService;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');
      this.datasetId = params.get('datasetId');
      this.tableId = params.get('tableId');
    });

    this.timeout();
    this.dataService.token = this.userService.getToken();

    this.dataService.gapiService.onLoad().subscribe(() => {
      // Here we can use gapi
      this.dataService.gapi = gapi;

      gapi.load('client', () => {
        this.dataService.gapi.client.setToken({access_token: this.dataService.token});
        // this.dataService.queryBQ({
        //   'query': 'SELECT * FROM [solasta-data:demo_data.demo_pii_internal] LIMIT 10'
        // });
        const tableRef = {
          projectId: this.projectId,
          datasetId: this.datasetId,
          tableId: this.tableId
        };

        const qData = 'maxResults=10&startIndex=0';
        this.dataService.listBQData(tableRef, qData).subscribe((data: any) => {
          console.log('List data received.');
          console.log(data);

          const headers: BQTableHeader[] = [];
          const rows: BQTableRow[] = [];

          this.dataService.tableFields.forEach((item) => {
            headers.push({name: item.name});
          });

          data.forEach((item: any) => {
            const values: any[] = [];
            item.f.forEach((val: any) => {
              values.push({string_value: val.v});
            });
            rows.push({values: values});
          });

          this.dlpInspect = {
            // inspectTemplateName: 'projects/solasta-data/inspectTemplates/test',
            inspectConfig: {
              infoTypes: [],
              minLikelihood: 'POSSIBLE'
            },
            item: {
              table: {
                headers: headers,
                rows: rows
              }
            }
          };

          console.log(this.dlpInspect);

          // const body = {
          //   "inspectTemplateName": "projects/solasta-data/inspectTemplates/test",
          //   "item": {
          //     "table": {
          //       "headers": [
          //         {
          //           "name": "column1"
          //         }
          //       ],
          //       "rows": [
          //         {
          //           "values": [
          //             {
          //               "string_value": "My phone number is (206) 555-0123"
          //             }
          //           ]
          //         }
          //       ]
          //     }
          //   }
          // };

          this.dataService.dlpInspectContent(this.projectId, this.dlpInspect).subscribe((dlpResult: any) => {
            console.log(dlpResult);
            this.dlpFindings = dlpResult;
          });
        });
      });
    });
  }

  private timeout() {
    console.log('Running timeout...');
    setTimeout(() => {
      if (this.progress < 100) {
          this.progress += 5;
          this.timeout();
      } else {
        this.dataLoaded = true;
      }
    }, 300);
  }
}
