import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserService } from './user.service';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule} from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  GoogleApiModule,
  GoogleApiService,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
  GoogleApiConfig
} from 'ng-gapi';
import {GoogleAuthService} from './google-auth.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { BqDataComponent } from './bq-data/bq-data.component';
import { AutomationComponent } from './automation/automation.component';
import { DataRegisterComponent } from './data-register/data-register.component';
import { ManageDlpComponent } from './manage-dlp/manage-dlp.component';
import { ProjectsComponent } from './projects/projects.component';
import { DatasetsComponent } from './datasets/datasets.component';
import { TablesComponent } from './tables/tables.component';
import { DataprocessingComponent } from './dataprocessing/dataprocessing.component';
import { AddNumberPipe } from './add-number.pipe';

const gapiClientConfig: NgGapiClientConfig = {
  client_id: '975620623495-t91sm2v7jr3d4qvceiojkbugbjhk2ivq.apps.googleusercontent.com',
  discoveryDocs: ['https://datastore.googleapis.com/$discovery/rest?version=v1'],
  scope: [
    'https://www.googleapis.com/auth/cloud-platform',
    'https://www.googleapis.com/auth/datastore',
    'https://www.googleapis.com/auth/bigquery',
    'https://www.googleapis.com/auth/cloud-platform.read-only',
    'https://www.googleapis.com/auth/cloud-platform',
    'https://www.googleapis.com/auth/bigquery.readonly'
  ].join(' '),
  ux_mode: 'popup'
};

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    BqDataComponent,
    AutomationComponent,
    DataRegisterComponent,
    ManageDlpComponent,
    ProjectsComponent,
    DatasetsComponent,
    TablesComponent,
    DataprocessingComponent,
    AddNumberPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatProgressBarModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    AppRoutingModule,
  ],
  providers: [UserService, GoogleAuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
