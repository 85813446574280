import {FormStringInput} from './form-string-input';
import {FormDateInput} from './form-date-input';
import {FormNumberInput} from './form-number-input';

export class DlpConfig {
  conf_name: FormStringInput;
  dataset_label_filter: FormStringInput;
  job_template: FormStringInput;
  job_topic: FormStringInput;
  last_start_date: FormDateInput;
  output_dataset_id: FormStringInput;
  output_project_id: FormStringInput;
  output_table_id: FormStringInput;
  rows_limit: FormNumberInput;
  sample_method: FormStringInput;
}
