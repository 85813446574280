import { Component, OnInit, NgZone } from '@angular/core';
import {UserService} from '../user.service';
import { GoogleApiService } from 'ng-gapi';
import {DLPConfigQuery} from '../dlpconfig-query';
import {DlpConfig} from '../dlp-config';

@Component({
  selector: 'app-manage-dlp',
  templateUrl: './manage-dlp.component.html',
  styleUrls: ['./manage-dlp.component.css']
})
export class ManageDlpComponent implements OnInit {
  token: string;
  projectId: string;
  q: DLPConfigQuery;
  dlpConfigs: DlpConfig[] = [];

  constructor(gapiService: GoogleApiService, userService: UserService, private zone: NgZone) {
    this.token = userService.getToken();
    this.projectId = 'im-dev-219022';
    this.zone = zone;
    this.q = {
      'partitionId': {
        'namespaceId': 'DLP-API',
        'projectId': this.projectId
      },
      'query': {
        'kind': [
          {
            'name': 'Config'
          }
        ],
        'filter': {
          'propertyFilter': {
            'property': {
              'name': 'conf_name'
            },
            'value': {
              'stringValue': 'default_config'
            },
            'op': 'EQUAL'
          }
        }
      }
    };

    gapiService.onLoad().subscribe(() => {
      // Here we can use gapi
      gapi.load('client', () => {
        const args = {
          path: `https://datastore.googleapis.com/v1beta3/projects/${this.projectId}:runQuery`,
          method: 'POST',
          body: this.q
        };
        console.log('Getting token...');
        gapi.client.setToken({access_token: userService.getToken()});

          gapi.client.request(args)
          .then((response) => {
            zone.run(() => { // Using NgZone here to make sure angular updates the UI.
            console.log(response);
              const ds = response.result.batch.entityResults;
              for (const entity of ds) {
                this.dlpConfigs.push(this.createConf(entity));
              }
            });
          }, (rejection) => {
            console.log(rejection);
          });

      });
    });
  }

  ngOnInit() {
  }

  public updateObject(ev) {
    console.log('Updating object...');
    const args = {
      path: `https://datastore.googleapis.com/v1beta3/projects/${this.projectId}:runQuery`,
      method: 'POST',
      body: this.q
    };
    console.log('Getting token...');
      gapi.client.request(args)
      .then((response) => {
        this.zone.run(() => { // Using NgZone here to make sure angular updates the UI.
        console.log(response);
          const ds = response.result.batch.entityResults;
          for (const entity of ds) {
            this.dlpConfigs.push(this.createConf(entity));
          }
        });
      }, (rejection) => {
        console.log(rejection);
      });
  }

  private createConf(entity) {
    const conf = new DlpConfig();
    conf.conf_name = {
      displayName: 'Configuration Name',
      type: 'string',
      value: entity.entity.properties.conf_name.stringValue
    };
    conf.dataset_label_filter = {
      displayName: 'Dataset label filter',
      type: 'string',
      value: entity.entity.properties.dataset_label_filter.stringValue
    };
    conf.job_template = {
      displayName: 'Job template',
      type: 'textarea',
      value: entity.entity.properties.job_template.stringValue
    };
    conf.job_topic = {
      displayName: 'Job PubSub Topic',
      type: 'string',
      value: entity.entity.properties.job_topic.stringValue
    };
    conf.last_start_date = {
      displayName: 'Last start date',
      type: 'Date',
      value: entity.entity.properties.last_start_date.timestampValue
    };
    conf.output_project_id = {
      displayName: 'Output project id',
      type: 'string',
      value: entity.entity.properties.output_project_id.stringValue
    };
    conf.output_dataset_id = {
      displayName: 'Output dataset id',
      type: 'string',
      value: entity.entity.properties.output_dataset_id.stringValue
    };
    conf.output_table_id = {
      displayName: 'Output table id',
      type: 'string',
      value: entity.entity.properties.output_table_id.stringValue
    };
    conf.sample_method = {
      displayName: 'Sample method',
      type: 'string',
      value: entity.entity.properties.sample_method.stringValue
    };
    conf.rows_limit = {
      displayName: 'Rows limit',
      type: 'number',
      value: entity.entity.properties.rows_limit.integerValue
    };

    return conf;

  }
}
