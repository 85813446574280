import { Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';
import { DataService} from '../data.service';
import { ApprovalMessage} from '../approval-message';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.css']
})
export class AutomationComponent implements OnInit {
  private message: ApprovalMessage;
  constructor(private userService: UserService, private dataService: DataService) {
    this.message = new ApprovalMessage();
    this.message.text = 'Hello, World!';
    this.message.attachments = [
      {
        'text': 'Approve',
        'fallback': 'You are unable to approve',
        'callback_id': 'approve_request',
        'color': '#3AA3E3',
        'attachment_type': 'default',
        'actions': [
          {
            'name': 'choice',
            'text': 'Approve',
            'type': 'button',
            'value': 'approve'
          },
          {
            'name': 'choice',
            'text': 'Deny',
            'type': 'button',
            'value': 'deny',
            'confirm': {
              'title': 'Are you sure?',
              'text': 'Are you certain you wish to deny this request?',
              'ok_text': 'Yes',
              'dismiss_text': 'No'
            }
          }
        ]
      }
    ];
  }

  ngOnInit() {
  }

  public isLoggedIn(): boolean {
    return this.userService.isUserSignedIn();
  }
  public doApprovalRequest() {
    console.log('Doing approval request...');
    // this.dataService.doAutomationApproval(this.message);
  }

}
