import { Component, OnInit, NgZone } from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { GoogleApiService } from 'ng-gapi';
import {UserService} from '../user.service';
import {DsTypeQuery} from '../ds-type-query';
import {DataSet} from '../dataSet';
import {DataService} from '../data.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = [];  // = ['One', 'Two', 'Three'];
  DataSets: DataSet[] = [];
  projects: any[] = [];
  filteredOptions: Observable<string[]>;
  token: string;
  dataLoaded = false;

  constructor(gapiService: GoogleApiService, userService: UserService, private zone: NgZone) {
    this.token = userService.getToken();

    gapiService.onLoad().subscribe(() => {
      // Here we can use gapi
      gapi.load('client', () => {

        const args = {
          path: 'https://cloudresourcemanager.googleapis.com/v1/projects',
          method: 'GET'
        };

        gapi.client.setToken({access_token: this.token});

          gapi.client.request(args)
          .then((response) => {
            zone.run(() => { // Using NgZone here to make sure angular updates the UI.
              console.log(response);
              this.dataLoaded = true;
              const ds = response.result.projects;
              for (const val of ds) {
                this.projects.push(val);
              }
            });
          }, (rejection) => {
            console.log(rejection);
            this.dataLoaded = true;
          });

      });
    });
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

}
