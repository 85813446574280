import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsComponent } from './projects/projects.component';
import { DatasetsComponent } from './datasets/datasets.component';
import { TablesComponent } from './tables/tables.component';
import { DataRegisterComponent} from './data-register/data-register.component';
import { DataprocessingComponent } from './dataprocessing/dataprocessing.component';

const routes: Routes = [
  { path: '', redirectTo: 'projects', pathMatch: 'full' },

  { path: 'projects', component: ProjectsComponent },
  { path: 'datasets/:projectId', component: DatasetsComponent },
  { path: 'tables/:projectId/:datasetId', component: TablesComponent },
  { path: 'registertable/:projectId/:datasetId/:tableId', component: DataRegisterComponent },
  { path: 'dataprocessing/:projectId/:datasetId/:tableId', component: DataprocessingComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
