import { Injectable } from '@angular/core';
import { Observable, Observer, of as observableOf } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GoogleApiService } from 'ng-gapi';

import GoogleAuth = gapi.auth2.GoogleAuth;

/**
 * FIXME 2018-09-18 : This class is a copy from https://github.com/
 rubenCodeforges/ng-gapi to bypass the issue : https://github.com/rubenCodeforges/ng-gapi/issues/63
 */
@Injectable()
export class GoogleAuthService {
  private GoogleAuth: GoogleAuth = undefined;

  constructor(private googleApi: GoogleApiService) {
    this.googleApi.onLoad().subscribe(() => {
      this.loadGapiAuth();
    });
  }

  public getAuth(): Observable<GoogleAuth> {
    if (!this.GoogleAuth) {
      return this.googleApi.onLoad().pipe(mergeMap(() => this.loadGapiAuth()));
    }
    return observableOf(this.GoogleAuth);
  }

  private loadGapiAuth(): Observable<GoogleAuth> {
    return Observable.create((observer: Observer<GoogleAuth>) => {
      gapi.load('auth2', () => {
        gapi.auth2.init(this.googleApi.getConfig().getClientConfig()).then((auth: GoogleAuth) => {
          this.GoogleAuth = auth;
          observer.next(auth);
          observer.complete();
        });
      });
    });
  }
}
