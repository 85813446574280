import { Component, OnInit } from '@angular/core';
import { DataSet} from './dataSet';
import { DataService } from './data.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from './user.service';
import { GoogleApiService } from 'ng-gapi';
import { GoogleAuthService} from './google-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers:  [ DataService ]
})
export class AppComponent implements OnInit {
  title = 'Data Manager';
  dataSet: DataSet[];
  menu = {isOpen: false};

  constructor(private userService: UserService,
              private dataService: DataService,
              private authService: GoogleAuthService,
              private gapiService: GoogleApiService) {
    this.gapiService.onLoad().subscribe();
  }

  ngOnInit(): void {

  }

  public isLoggedIn(): boolean {
    return this.userService.isUserSignedIn();
  }

  public signIn() {
    this.userService.signIn();
    //   this.authService.getAuth().subscribe((auth) => {
    //     if (auth.isSignedIn.get()) {
    //       console.log(auth.currentUser.get().getBasicProfile())
    //     }else {
    //       auth.signIn().then((response) => {
    //         console.log(response.getBasicProfile());
    //       })
    //     }

    //  })
  }

  public signOut() {
    this.userService.signOut();
  }

  public toggleMenu() {
    console.log('Toggleing menu...');
    this.menu.isOpen = !this.menu.isOpen;
  }
}
