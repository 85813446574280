import { Component, OnInit, NgZone } from '@angular/core';
import { UserService } from '../user.service';
import { GoogleApiService } from 'ng-gapi';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent implements OnInit {
  tables: any[] = [];
  projectId: string;
  datasetId: string;
  token: string;
  gapiService: GoogleApiService;
  dataLoaded = false;

  constructor(gapiService: GoogleApiService, userService: UserService,
    private route: ActivatedRoute, private zone: NgZone) {
    this.token = userService.getToken();
    this.gapiService = gapiService;
    this.zone = zone;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');
      this.datasetId = params.get('datasetId');
    });
    this.gapiService.onLoad().subscribe(() => {
      // Here we can use gapi
      gapi.load('client', () => {

        const args = {
          path: `https://www.googleapis.com/bigquery/v2/projects/${this.projectId}/datasets/${this.datasetId}/tables`,
          method: 'GET'
        };

        gapi.client.setToken({access_token: this.token});

          gapi.client.request(args)
          .then((response) => {
            this.zone.run(() => { // Using NgZone here to make sure angular updates the UI.
              console.log(response);
              this.dataLoaded = true;
              if (response.result.tables) {
                const tbs = response.result.tables;
                for (const val of tbs) {
                  this.tables.push(val);
                }
              }
            });
          }, (rejection) => {
            console.log(rejection);
            this.dataLoaded = true;
          });

      });
    });
  }
}
