import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addNumber'
})
export class AddNumberPipe implements PipeTransform {

  transform(value: any, num: number): number {
    if (!value) {
      value = 0;
    }
    return parseInt(value, 10) + num;
  }

}
