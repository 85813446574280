import { Component, OnInit, NgZone } from '@angular/core';
import { UserService } from '../user.service';
import { GoogleApiService } from 'ng-gapi';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-data-register',
  templateUrl: './data-register.component.html',
  styleUrls: ['./data-register.component.css']
})
export class DataRegisterComponent implements OnInit {
  fields: any[] = [];
  projectId: string;
  datasetId: string;
  tableId: string;
  token: string;
  gapiService: GoogleApiService;
  dataLoaded = false;
  dataService: DataService;

  constructor(gapiService: GoogleApiService, userService: UserService,
    private route: ActivatedRoute, private zone: NgZone,
    dataService: DataService
  ) {

    this.token = userService.getToken();
    this.gapiService = gapiService;
    this.zone = zone;
    this.dataService = dataService;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');
      this.datasetId = params.get('datasetId');
      this.tableId = params.get('tableId');
    });
    this.gapiService.onLoad().subscribe(() => {
      // Here we can use gapi
      gapi.load('client', () => {

        const args = {
          path: `https://www.googleapis.com/bigquery/v2/projects/${this.projectId}/datasets/${this.datasetId}/tables/${this.tableId}`,
          method: 'GET'
        };

        gapi.client.setToken({access_token: this.token});

          gapi.client.request(args)
          .then((response) => {
            this.zone.run(() => { // Using NgZone here to make sure angular updates the UI.
              console.log(response);
              this.dataLoaded = true;
              if (response.result.schema) {
                this.dataService.tableFields = response.result.schema.fields;
              }
            });
          }, (rejection) => {
            console.log(rejection);
            this.dataLoaded = true;
          });

      });
    });
  }
}
