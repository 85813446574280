import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  links = [
    {
      title: 'Projects',
      url: '/projects'
    }
    // {
    //   title: 'Manage DLP',
    //   url: '/managedlp'
    // },
    // {
    //   title: 'Automation',
    //   url: '/automation'
    // },
    // {
    //   title: 'Data Search',
    //   url: '/datasearch'
    // }
    // {
    //   title: 'Register Data',
    //   url: '/dataregister'
    // }
  ];

  @Output()
  itemClick: EventEmitter<String> = new EventEmitter<String>();

  constructor() {
  }

  ngOnInit() {
  }
  toggleMenu(event) {
    this.itemClick.emit(null);
  }

}
