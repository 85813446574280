import { Component, OnInit, NgZone} from '@angular/core';
import { UserService } from '../user.service';
import { GoogleApiService } from 'ng-gapi';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-datasets',
  templateUrl: './datasets.component.html',
  styleUrls: ['./datasets.component.css']
})
export class DatasetsComponent implements OnInit {
  datasets: any[] = [];
  projectId: string;
  token: string;
  gapiService: GoogleApiService;
  dataLoaded = false;

  constructor(gapiService: GoogleApiService, userService: UserService,
    private route: ActivatedRoute, private zone: NgZone) {
    this.token = userService.getToken();
    this.gapiService = gapiService;
    this.zone = zone;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');
    });
    this.gapiService.onLoad().subscribe(() => {
      // Here we can use gapi
      gapi.load('client', () => {

        const args = {
          path: `https://www.googleapis.com/bigquery/v2/projects/${this.projectId}/datasets`,
          method: 'GET'
        };

        gapi.client.setToken({access_token: this.token});

          gapi.client.request(args)
          .then((response) => {
            this.zone.run(() => { // Using NgZone here to make sure angular updates the UI.
              console.log(response);
              this.dataLoaded = true;
              if (response.result.datasets) {
                const ds = response.result.datasets;
                for (const val of ds) {
                  this.datasets.push(val);
                }
              }
            });
          }, (rejection) => {
            console.log(rejection);
            this.dataLoaded = true;
          });

      });
    });
  }
}
