import { Injectable } from '@angular/core';
import { GoogleApiService } from 'ng-gapi';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  token: string;
  gapiService: GoogleApiService;
  gapi: any;
  tableFields: any[] = [];

  constructor(gapiService: GoogleApiService) {
    this.gapiService = gapiService;
  }

  public listBQData (tableRef: any, qData: string): Observable<any[]> {
    /*
     List BQ data by row index rather than scanning entire table with a Query.
     Doing a select * query even with a LIMIT would process the entire table
     and possible cost a lot.

     qData: String = 'maxResults=[int]&startIndex=[int]'
    */

    console.log('Performing BQ list data...');

    const projectId = tableRef.projectId;
    const datasetId = tableRef.datasetId;
    const tableId = tableRef.tableId;

    const args = {
      path: `https://bigquery.googleapis.com/bigquery/v2/projects/${projectId}/datasets/${datasetId}/tables/${tableId}/data?${qData}`,
      method: 'GET'
    };

    const ob = new Observable<any[]>(observer => {
      this.gapi.client.request(args)
        .then((response: any) => {
          observer.next(response.result.rows);
        }, (rejection: any) => {
          console.log(rejection);
          observer.next([]);
        });
    });

    return ob;
  }

  public queryBQ (body: any) {
    console.log('Performing BQ query...');
    const args = {
      path: `https://www.googleapis.com/bigquery/v2/projects/solasta-data/queries`,
      method: 'POST',
      body: body
    };

    this.gapi.client.request(args)
      .then((response) => {
        console.log(response);
      }, (rejection) => {
        console.log(rejection);
      });
  }

  public dlpInspectContent (parentProject: string, body: any): Observable<any[]> {
    console.log('Inspecting content...');

    const args = {
      path: `https://content-dlp.googleapis.com/v2/projects/${parentProject}/content:inspect?key=${environment.apiKey}`,
      method: 'POST',
      body: body
    };

    const ob = new Observable<any[]>(observer => {
      this.gapi.client.request(args)
        .then((response: any) => {
          observer.next(response.result.result.findings);
        }, (rejection: any) => {
          console.log(rejection);
          observer.next([]);
        });
    });

    return ob;
  }
}
